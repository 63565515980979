body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Layout-root-1 {
  background-color: #fff !important;
}

.ant-upload.ant-upload-select-picture-card {
  min-width: 260px;
  min-height: 260px;
  position: absolute;
  z-index: 999;
  background-color: transparent !important;
  color: transparent;
}
.ant-upload.ant-upload-select-picture-card .ant-upload {
  color: #fff;
}
.ant-upload.ant-upload-select-picture-card:hover .ant-upload {
  color: #fff;
}


.ant-upload.ant-upload-select-picture-card:hover {
  background-color: rgba(0,0,0,.4) !important;
  color: #fff;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  width: 260px !important;
  height: 260px !important;
}

.material-ui-tabs hr {
  display: none;
}
